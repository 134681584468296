<template>
  <div>
    <b-card>
      <b-button
        :to="{ name: 'staff-high-possibility-novel-fill-form' }"
        variant="primary"
        class="d-block d-lg-inline"
      >
        บันทึกเสี่ยงสูง "รายใหม่"
      </b-button>
      <b-button
        :to="{ name: 'staff-novel-list' }"
        variant="primary"
        class="ml-0 ml-lg-2 mt-1 mt-lg-0 d-block d-lg-inline"
      >
        รายการทั้งหมด
      </b-button>
      <b-button
        :to="{ name: 'staff-novel-full-form-create-new' }"
        variant="primary"
        class="ml-0 ml-lg-2 mt-1 mt-lg-0 d-block d-lg-inline"
      >
        สร้างรายการใหม่
      </b-button>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
}
</script>

<style>

</style>
